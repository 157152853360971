html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    background-color: #f8f8f8;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

h1 {
    font-size: 32px;
    color: #424242;
    font-family: 'La Belle Aurore', normal;
    font-weight: normal;
}

.shown {
    display: block;
}
.hidden {
    display: none;
}

@font-face {
    font-family: 'Niramit';
    src: url('/fonts/Niramit/Niramit-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Niramit';
    src: url('/fonts/Niramit/Niramit-Bold.ttf');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'La Belle Aurore';
    src: url('/fonts/La-Belle-Aurore/LaBelleAurore-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}
